import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import { navigate } from "gatsby"
import DotaznikInner from "./dotaznik";
import PrehledInner from "./prehled";
import { useCookies } from "react-cookie";
import axios from "axios";
import Block from "../../components/Block";


// const Default = () => {
//   navigate("/404/");
//   return (<></>);
// }


const Redirect = ({url}) => {
  if (typeof window !== 'undefined') {
    navigate(url);
  }
  
  return (<></>);
}


const Nabidka = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['loginSess']);

  const login = (sessId) => {
    console.log("ted jsem prihlasil");

    setCookie("loginSess", sessId, { path: '/' });
  }

  const logOut = () => {
    setCookie("loginSess", "", { path: '/' });
    navigate("/")
  }

  const getLoginId = () => {
    return(cookies.loginSess);
  }




  let isLoggedIn;

  if (cookies.loginSess) {
    if (cookies.loginSess !== "") {
      console.log("přihlášeno");
      console.log(getLoginId());
      isLoggedIn = true;
    }
  }

  else {
    console.log("odhlášeno");
    console.log(getLoginId());
    isLoggedIn = false;
  }




  useEffect(() => {

    // Když uživatel refreshne jakoukoliv stránku a není přihlášenej tak ho to vrátí na začátek
    // Pokud je přihlášenej tak může refreshnout klidně formulář a appka ho nechá na té stránce

    if (isLoggedIn === false) {
      // navigate("/nabidka/");
    }

    

    

	}, []);

  return (

    <Layout simpleHeader noFooter isLoggedIn={isLoggedIn} logOut={logOut}>

      <SEO
        title="Sestavte si pohřeb | Goodbye.cz"
        customTitle
        description="Nabízíme pohřební služby (Celá ČR: Praha, Brno, atd.): pohřeb s obřadem, kremace bez obřadu. Vše naplánujete z bezpečí domova. A za transparentní ceny."
        noindex
        image={"/pohrebnisluzbaOg.png"}
      />


      <Router basepath="/nabidka/">
 
        {isLoggedIn ?
          <>
            <Redirect
              url="/nabidka/prehled/"
              path="/"
            />

            <Redirect
              url="/nabidka/prehled/"
              path="/dotaznik/"
            />

            <Redirect
              url="/nabidka/prehled/"
              path="/dotaznik/konec/"
            />

            <PrehledInner
              path="/prehled/*"
              getLoginId={getLoginId}
            />
          </>
          :
          <>
            <Redirect
              url="/nabidka/dotaznik/"
              path="/"
            />

            <Redirect
              url="/nabidka/dotaznik/"
              path="/prehled/"
            />

            <DotaznikInner
              path="/dotaznik/*"
              login={login}
            />
          </>
        }

        {/* <Default default /> */}

      </Router>
    </Layout>
  )
}

export default Nabidka